import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { removeFromLS } from '@/utils/LS';
import { EmailEditorModalHeader } from './EmailEditorModalHeader';
import { customCss } from './helpers/customCss';
import { customJS } from './helpers/customJS';
import { features } from './helpers/features';
import { loadDesign } from './helpers/loadDesign';
import { loadScript } from './helpers/loadScript';
import { registerLS } from './helpers/registerLS';
import { registerPreviewHtml } from './helpers/registerPreviewHtml';
import { tools } from './helpers/tools';
import { translations } from './helpers/translations';
import { useRegisterImages, } from './helpers/useRegisterImages';
import { useUnlayerBlocks, } from './helpers/useUnlayerBlocks';
import { useUnlayerDesignTags, } from './helpers/useUnlayerDesignTags';
import { useUnlayerMergeTags, } from './helpers/useUnlayerMergeTags';
import styles from './styles.module.scss';
export const EmailEditorModal = ({ unlayerUrl, unlayerProjectId, title, initialDesign, onChange, onClose, context = {}, draftDesignLSKey = '', uploadImage = () => Promise.resolve(''), }) => {
    const { i18n } = useTranslation();
    const isLoaded = useRef(false);
    const designTags = useUnlayerDesignTags(context);
    const blocks = useUnlayerBlocks(context);
    const { mergeTags, variableIds } = useUnlayerMergeTags(context);
    const registerImages = useRegisterImages(uploadImage);
    useEffect(() => {
        if (isLoaded.current)
            return;
        loadScript(unlayerUrl, () => {
            isLoaded.current = true;
            window.unlayer.init({
                id: 'email-editor',
                projectId: unlayerProjectId,
                displayMode: 'email',
                safeHtml: false,
                locale: i18n.language === 'ru' ? 'ru-RU' : 'en-US',
                translations,
                tools,
                designTags,
                features,
                mergeTags,
                blocks,
                customCSS: [customCss],
                customJS: [customJS(variableIds)],
                fonts: {
                    showDefaultFonts: true,
                },
            });
            registerImages(window.unlayer);
            registerPreviewHtml(window.unlayer);
            registerLS(window.unlayer, draftDesignLSKey);
            loadDesign(window.unlayer, initialDesign);
        });
    }, []);
    const handleSave = () => {
        window.unlayer.exportHtml((data) => {
            let html = data.html.split('</head>');
            html[0] +=
                ' <style>' +
                    `@media (max-width: 620px) {
          .u-row > div,
          .u-row .u-col {
            display: block !important;
            width: 100% !important;
            min-width: 0px !important;
            max-width: 1000px !important;
          }

          .u-row-container.hide-mobile {
            display: none !important;
          }

          .u-row.no-stack {
            display: table !important;
            table-layout: fixed !important;
          }

          .u-row.no-stack > div {
            display: table-row !important;
          }

          .u-row.no-stack .u-col {
            display: table-cell !important;
            width: 1px !important;
          }
        }` +
                    '</style> ' +
                    '</head>';
            html = html.join('');
            onChange({
                design: JSON.stringify(data.design),
                html,
            });
        });
    };
    const onCloseWithoutSaving = () => {
        removeFromLS({ key: draftDesignLSKey });
        onClose();
    };
    return createPortal(_jsxs("div", Object.assign({ className: styles.editorWrapper }, { children: [_jsx(EmailEditorModalHeader, { title: title, onSave: handleSave, onClose: onCloseWithoutSaving }), _jsx("div", { id: 'email-editor' })] })), document.body);
};
